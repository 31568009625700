<template>
  <b-container style="margin-top: 80px;">
    <h4 class="display-3 mt-1 mb-4 font-weight-bold text-center">
      วีดีโอสอนการใช้งานระบบ
    </h4>

    <page-content
      v-for="(tutorial, index) in tutorials"
      :key="`tutorial-${index}`"
      class="mb-4"
    >
      <h5 class="display-4 mt-1 mb-2 font-weight-bold text-center">
        {{ tutorial.title }}
      </h5>
      <!-- https://d10a8qjc50njp0.cloudfront.net/videos/HBD+D.A.R.E.mov -->
      <b-row align-h="center">
        <b-col cols="12">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <b-embed type="video" controls>
              <source :src="`${tutorial.url}#t=${tutorial.time}`" type="video/mp4" />
            </b-embed>
          </a>
        </b-col>
      </b-row>
    </page-content>
  </b-container>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import PageTitle from "../../components/layout/PageTitle";

export default {
  components: {
    PageContent,
    // PageTitle
  },

  data() {
    return {
      tutorials: [
        {
          title: "1. การเข้าสู่ระบบแดร์ประเทศไทย",
          url: "https://d10a8qjc50njp0.cloudfront.net/videos/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%AA%E0%B8%B9%E0%B9%88%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B9%81%E0%B8%94%E0%B8%A3%E0%B9%8C%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2.mp4",
          time: 2,
        },
        {
          title: "2. การวางแผนการสอน",
          url: "https://d10a8qjc50njp0.cloudfront.net/videos/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A7%E0%B8%B2%E0%B8%87%E0%B9%81%E0%B8%9C%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99.mp4",
          time: 0,
        },
        {
          title: "3. การบันทึกข้อมูลการสอน อัพโหลดหลักฐานการสอน",
          url: "https://d10a8qjc50njp0.cloudfront.net/videos/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A7%E0%B8%B2%E0%B8%87%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99+%E0%B8%AD%E0%B8%B1%E0%B8%9E%E0%B9%82%E0%B8%AB%E0%B8%A5%E0%B8%94%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%90%E0%B8%B2%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99.mp4",
          time: 2,
        },
        {
          title: "4. การออกรายงานขอเบิกค่าตอบแทนการสอน",
          url: "https://d10a8qjc50njp0.cloudfront.net/videos/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AD%E0%B8%AD%E0%B8%81%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%82%E0%B8%AD%E0%B9%80%E0%B8%9A%E0%B8%B4%E0%B8%81%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B8%95%E0%B8%AD%E0%B8%9A%E0%B9%81%E0%B8%97%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99.mp4",
          time: 2,
        },
        {
          title: "5. การพิมพ์หนังสือรับรองเพื่อออกใบประกาศหลังเรียนจบ",
          url: "https://d10a8qjc50njp0.cloudfront.net/videos/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9E%E0%B8%B4%E0%B8%A1%E0%B8%9E%E0%B9%8C%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%A3%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%9E%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%AD%E0%B8%AD%E0%B8%81%E0%B9%83%E0%B8%9A%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A8%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%88%E0%B8%9A.mp4",
          time: 2,
        },
      ],
    };
  },
};
</script>